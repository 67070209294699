export const config = {
  sentry: {
    dsn: process.env.sentryDSN,
  },
  logrocket: {
    appId: process.env.NEXT_PUBLIC_LOGROCKET_APP_ID,
    release: process.env.NEXT_PUBLIC_GIT_SHA || "development",
  },
  datadog: {
    applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
    clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
  },
  rudderstack: {
    writeKey: process.env.NEXT_PUBLIC_RUDDERSTACK_WRITE_KEY,
    dataPlaneUrl: process.env.NEXT_PUBLIC_RUDDERSTACK_DATA_PLANE_URL,
  },
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT || "development",
  identityDomain: process.env.NEXT_PUBLIC_IDENTITY_DOMAIN,
  supergraph: { url: process.env.NEXT_PUBLIC_SUPERGRAPH_URL },
  filestack: {
    apiKey: process.env.NEXT_PUBLIC_FILESTACK_API_KEY,
  },
  mapbox: process.env.mapboxAccessToken,
  preventDarkMode: process.env.preventDarkMode,
  connect: {
    url: process.env.NEXT_PUBLIC_CONNECT_URL,
  },
};
